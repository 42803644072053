import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import { peopleArr } from "../../data/people-details"
import { ImageComponent } from "../../components/image-component"
// import fbTeamLogo from "../../images/fb-team.svg"
// import inTeamLogo from "../../images/in-team.svg"
import cheese1 from "../../images/team/cheese1.png"
import cheese2 from "../../images/team/cheese2.png"
import cheese3 from "../../images/team/cheese3.png"
import trust from "../../images/team/trust.png"
import love from "../../images/team/love.png"
import good from "../../images/team/good.png"
import opti from "../../images/team/opti.png"

import "../../styles/aboutus.css"
import "../../styles/people.css"
import Slider from "react-slick"

const AboutUsPage = () => {
  const imageSliderSettings = {
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
  }
  return (
    <Layout>
      <div className="about-us-image-container">
        <div>
          {typeof window !== "undefined" && window.innerWidth >= 700 ? (
            <Slider {...imageSliderSettings}>
              <div className="about-us-slider-image au-image-one"></div>
              <div className="about-us-slider-image au-image-two"></div>
              <div className="about-us-slider-image au-image-three"></div>
              <div className="about-us-slider-image au-image-four"></div>
              <div className="about-us-slider-image au-image-five"></div>
            </Slider>
          ) : (
            <Slider {...imageSliderSettings}>
              <div className="about-us-slider-image au-image-one"></div>
              <div className="about-us-slider-image au-image-two"></div>
              <div className="about-us-slider-image au-image-three"></div>
              <div className="about-us-slider-image au-image-four"></div>
            </Slider>
          )}
        </div>
        <div className="about-us-image-content">
          <div className="about-us-image-mission">Our Mission</div>
          <div className="about-us-image-make">To make Farmers Prosperous</div>
        </div>
      </div>
      <div className="about-us-section-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            About <span className="heading-bold-text">Us</span>
          </div>
        </div>
        <div className="about-us-section-main">
          {/*<div className="about-us-section-image">*/}
          {/*</div>*/}
          <div className="about-us-section-content">
            <div className="about-us-section-content-description">
              The people who work at MoooFarm are driven by the idea that the
              optimum results are born out of perseverance, determination and
              strong team-connection. A group of young, dynamic and optimistic
              individuals coming from diverse range of backgrounds and
              experience, but with the Mutual goal - to empower the Dairy
              Farmers. This is team MoooFarm, a lot of smiling happy people who
              work hard to put a smile on the face of our Farmers.
            </div>
            {/*<div className="general-button view-member-button">*/}
            {/*  <Link to="#team">View Members</Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="about-us-cheese-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            A world <span className="heading-bold-text">With</span> Cheese or{" "}
            <span className="heading-bold-text">Without</span> Cheese
          </div>
        </div>
        <div className="about-us-cheese-main">
          <div className="about-us-cheese-indi-div">
            <div className="about-us-cheese-indi-image">
              <img src={cheese1} alt="answer" />
            </div>
            <div className="about-us-cheese-indi-content">
              The answer varies to whom you ask this...
            </div>
          </div>
          <div className="about-us-cheese-indi-div div-reverse">
            <div className="about-us-cheese-indi-image">
              <img src={cheese2} alt="answer" />
            </div>
            <div className="about-us-cheese-indi-content">
              But what we know in our hearts is that 100 million dairy farmers
              deserve to be prosperous
            </div>
          </div>
          <div className="about-us-cheese-indi-div">
            <div className="about-us-cheese-indi-image">
              <img src={cheese3} alt="answer" />
            </div>
            <div className="about-us-cheese-indi-content">
              And for them we are building connected commerce to ensure India's
              dairy sector is organized into a thriving industry
            </div>
          </div>
        </div>
      </div>
      <div className="about-us-value-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            <span className="heading-bold-text">Values</span> we Stand by
          </div>
        </div>
        <div className="about-us-value-main">
          <div className="about-us-value-indi-div">
            <div className="about-us-value-indi-image">
              <img src={trust} alt={"trust"} />
            </div>
            <div className="about-us-value-indi-text">Trust</div>
          </div>
          <div className="about-us-value-indi-div">
            <div className="about-us-value-indi-image">
              <img src={love} alt={"love"} />
            </div>
            <div className="about-us-value-indi-text">Farmer Love</div>
          </div>
          <div className="about-us-value-indi-div">
            <div className="about-us-value-indi-image">
              <img src={good} alt={"good"} />
            </div>
            <div className="about-us-value-indi-text">
              Collaboration for greater good
            </div>
          </div>
          <div className="about-us-value-indi-div">
            <div className="about-us-value-indi-image">
              <img src={opti} alt={"trust"} />
            </div>
            <div className="about-us-value-indi-text">Ascending Optimism</div>
          </div>
        </div>
      </div>
      <div id="team" className="team-container">
        <div className="team-wrapper">
          <div className="container-heading-wrapper">
            <div className="container-heading-line"></div>
            <div className="container-heading">
              Meet our <span className="heading-bold-text">Founders</span>
            </div>
          </div>
          <div className="team-main-container">
            {peopleArr &&
              peopleArr.map((people, index) => {
                return (
                  <div
                    className={`team-indi-div ${
                      index % 2 === 1 ? "right-image-order" : ""
                    }`}
                  >
                    <div className="tid-image-container">
                      <ImageComponent activeImage={people.activeImage} />
                      <div className="tid-image-content">
                        <div className="tid-ic-name">{people.name}</div>
                        <div className="tid-ic-designation">
                          {people.designation}
                        </div>
                      </div>
                    </div>
                    <div className="tid-description-container">
                      <div className="tid-dc-content">{people.content}</div>
                      {/*<div className="tid-dc-social-link">*/}
                      {/*  <a href={people.fbLink}>*/}
                      {/*    <img src={fbTeamLogo} alt="fb-logo" />*/}
                      {/*  </a>*/}
                      {/*  <a href={people.linkedinLink}>*/}
                      {/*    <img src={inTeamLogo} alt="fb-logo" />*/}
                      {/*  </a>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className="job-opening-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            <span className="heading-bold-text">Join</span> Our Team
          </div>
        </div>
        <div className="jo-main-container">
          <div className="jo-mc-content">
            If you think you are ready to be part of a dynamic, fast growing,
            versatile and young workforce, apply for our existing openings
            across cities today.
          </div>
          <div className="general-button see-opening-button">
            <Link to="/career">See openings</Link>
          </div>
          <div className="jo-mc-contact">
            Or email us at{" "}
            <a href="mailto:talent@moofarm.com">
              <span className="heading-bold-text">talent@mooofarm.com</span>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUsPage
