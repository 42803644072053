import React from "react"
// import css from "./image-component.css"

export function ImageComponent({
  activeImage,
  // inactiveImage
}) {
  return (
    <div className="tid-image">
      <img
        src={activeImage}
        // onMouseOver={e => e.currentTarget.src = activeImage}
        // onMouseOut={e => e.currentTarget.src = inactiveImage}
        // className="desaturate"
        alt={"people"}
      />
    </div>
  )
}
