import team1ActiveImg from "../images/team/1-a.png"
// import team1InactiveImg from "../images/team/1-in.png"
import team2ActiveImg from "../images/team/2-a.png"
// import team2InactiveImg from "../images/team/2-in.png"
import team3ActiveImg from "../images/team/3-a.png"
// import team3InactiveImg from "../images/team/3-in.png"
import team4ActiveImg from "../images/team/4-a.png"
// import team4InactiveImg from "../images/team/4-in.png"

export const peopleArr = [
  {
    name: "Param Singh",
    content:
      "Param is a seasoned entrepreneur and a strong advocate of startups across the globe. Param is a recipient of the prestigious scholarship from Stanford University in ‘Social Entrepreneurship’. His first start-up won six state and national awards and was acquired by an Australian public listed company in 2014. He has won many awards including Australia-India Young Entrepreneur of the Year in 2013, Distinguished alumnus of Central Queensland University in 2014, Global Alumni of Australia in 2016, Australians making a difference in India in 2018 by the Department of Foreign Affairs and Trade as well as “Nanak Nam Leva Prominent Personalities” State award from Punjab Government on Shri Guru Nanak’s 550th Birthday in 2019. Param drives the vision of the company and ensures frictionless functioning of all the pillars. He specifically focuses on both business development and strategic partnerships. ",
    designation: "CEO, Founder",
    fbLink: "",
    linkedinLing: "",
    // "inactiveImage": team1InactiveImg,
    activeImage: team1ActiveImg,
  },
  {
    name: "Aashna Singh",
    content:
      "Aashna is a strong promoter of inclusive growth and is passionate to create sustainable rural livelihood for farmers. She pursued her post-graduation with Double Distinction from the London School of Economics and University of Sydney Business School after completing her graduation (with distinction) from Lady Shri Ram College, Delhi University. Aashna was recently awarded as one of the ‘Most Influential Agriculture Industry Professionals” at the Agriculture Innovation Congress and Awards. Aashna, with her razor-sharp focus and fine execution skills, ensures the smooth on-ground operations for MoooFarm. ",
    designation: "COO, Founder",
    fbLink: "",
    linkedinLing: "",
    // "inactiveImage": team2InactiveImg,
    activeImage: team2ActiveImg,
  },
  {
    name: "Jitesh Arora",
    content:
      "Graduated from IIT Roorkee in 2017, Jitesh has 2+ years of experience as a Machine Learning Expert in core AI companies, like Fuzzy Logix and Fractal.ai. Apart from Machine/Deep Learning, Jitesh is also an expert in core Infrastructure development and technical deployment of projects. Before engaging with MoooFarm Jitesh had co-founded dairy tech traceability startup Erth.ai. Jitesh, with his meticulous approach and technical expertise, is leading the technology for MoooFarm.\n",
    designation: "CTO, Founder",
    fbLink: "",
    linkedinLing: "",
    // "inactiveImage": team3InactiveImg,
    activeImage: team3ActiveImg,
  },
  {
    name: "Abhijeet Mittal",
    content:
      "Graduated from IIT Roorkee in 2017 from Industrial branch, Abhijeet has 2+ years of industry experience as a Consultant with multiple companies ranging from fintech start-ups (like Sigmoid Analytics, SmartCoin) to Fortune 500 companies like Sears Holdings. Before engaging with MoooFarm, Abhijeet had cofounded dairy tech traceability startup Erth.ai. Abhijeet, with his innovative ideas, business mindset and thought clarity, ensures product market fit and leads the product team at MoooFarm.",
    designation: "CPO, Founder",
    fbLink: "",
    linkedinLing: "",
    // "inactiveImage": team4InactiveImg,
    activeImage: team4ActiveImg,
  },
]
